<template>
  <sdCards headless class="demo__card">
    <div class="bill__wrapper">
      <router-link :to="`/trading/${isInvestor ? 'invest' : 'public'}/${AccountId}`">
        <BillGraphComponent class="graph" :account="{ type: accountType, id: AccountId }" :color="[color]" />
      </router-link>
      <div class="account__footer">
        <p v-if="isInvestor" class="footer__invested-in">
          {{ i18n.t('accounts.public.invested_in') }}
        </p>
        <router-link :to="`/trading/${isInvestor ? 'invest' : 'public'}/${AccountId}`" class="footer__title">
          {{ BillAccount.name ?? BillAccount.trade.name }}
          {{ isInvestor ? `(${BillAccount.code})` : '' }}
        </router-link>
        <div class="footer__description">
          <ul class="footer__profit df-jcsb-aic mt-10">
            <li>
              <p v-html="i18n.t('accounts.public.profit_day')" />
              <p>{{ BillAccount.profit_day }}%</p>
            </li>
            <li>
              <p v-html="i18n.t('accounts.public.profit_month')" />

              <p>{{ BillAccount.profit_month }}%</p>
            </li>
            <li>
              <p v-html="i18n.t('accounts.public.profit_all')" />
              <p>{{ BillAccount.profit_all }}%</p>
            </li>
          </ul>
          <ul class="footer__properties df-jcsb-aic mt-10">
            <li>
              <p v-html="i18n.t('accounts.public.created', isInvestor ? 0 : 1)" />
              <p>{{ i18n.d(BillAccount.created_at, 'short') }}</p>
            </li>
            <li>
              <p v-html="i18n.t('accounts.public.trading')" />

              <p>{{ i18n.t('accounts.public.days') }} {{ BillAccount.lifetime }}</p>
            </li>
            <li>
              <template v-if="!isInvestor">
                <p v-html="i18n.t('accounts.public.risk_level_title')" />
                <p :class="`risk-level ${BillAccount.risk_level}`">
                  {{ i18n.t('accounts.public.risk_level.' + BillAccount.risk_level) }}
                </p>
              </template>
            </li>
            <li>
              <template v-if="isInvestor">
                <p v-html="i18n.t('accounts.public.balance')" />
                <p>{{ i18n.n(+BillAccount.amount, 'decimal') + ' ' + BillAccount.amount_currency }}</p>
              </template>
            </li>
          </ul>
        </div>
      </div>
      <PublicAccountButtons
        :isClosed="isClosed"
        :account="BillAccount"
        :isInvestor="isInvestor"
        @openModal="openModal"
      />
      <div class="bill__statuses">
        <Status :status="BillAccount.status" v-if="isClosed" />
        <Status
          :text="i18n.t(`statuses.trading_account.${BillAccount.status}`)"
          :status="BillAccount.status"
          v-else-if="BillAccount.status"
        />
        <Status
          class="footer__info"
          :text="i18n.t('accounts.public.self_invested')"
          status="active"
          v-if="BillAccount.self_invested"
        />
        <Status
          class="footer__info"
          :text="i18n.t('accounts.public.self_account')"
          status="leverage"
          v-else-if="BillAccount.owner"
        />
        <Status status="promo" class="_right" v-if="BillAccount.promo" />
      </div>
    </div>
  </sdCards>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent } from 'vue';

const Status = defineAsyncComponent(() => import('@/components/elements/Status'));
const TradingContestTag = defineAsyncComponent(() => import('@/components/elements/TradingContestTag'));
const BillGraphComponent = defineAsyncComponent(() => import('@/components/elements/BillGraphComponent.vue'));
const PublicAccountButtons = defineAsyncComponent(() => import('./PublicAccountButtons'));

const StartBills = {
  name: 'StartBills',
  components: { Status, BillGraphComponent, TradingContestTag, PublicAccountButtons },
  props: {
    BillAccount: {
      type: Object,
    },
    BillIcon: {
      type: String,
    },
    AccountId: {
      type: Number,
    },
    accountType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const openModal = (modalName, type) => {
      const data = {
        visible: true,
        data: {
          ...props.BillAccount,
          ...props.BillAccount.trade,
          id: props.AccountId,
        },
        type,
      };
      emit(`update:${modalName}Modal`, { ...JSON.parse(JSON.stringify(data)) });
    };
    const isClosed = computed(() => ['closing', 'closed'].includes(props.BillAccount.status));

    const isInvestor = computed(() => props.BillAccount.kind === 'investor');

    const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

    const color = computed(() => {
      switch (props.BillAccount.kind) {
        case 'real':
          return colors[1];
        case 'investor':
          return colors[3];
        case 'demo':
          return colors[4];
        default:
          return colors[4];
      }
    });

    return {
      i18n,
      color,
      isClosed,
      isInvestor,
      openModal,
    };
  },
};
export default StartBills;
</script>

<style scoped lang="scss">
.account__footer {
  .footer__title {
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-size: 20px !important;
    padding: 0 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 0px;

    &.footer__description {
      overflow: hidden;
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
      display: flex;
      word-wrap: break-word;
      box-orient: vertical;
      font-size: 14px;
    }

    &.footer__info {
      font-size: 14px;
      opacity: 0.5;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  padding: 5px 0;
}

.bill__statuses {
  .status {
    margin-left: 0;
    margin-right: 10px;

    &._right {
      margin-left: auto;
    }
  }
}

.footer__profit {
  background: rgba(240, 240, 240);
  padding: 20px 10px;
  border-radius: 5px;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1.3;
    gap: 5px;
    p {
      font-size: 14px;
    }
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.footer__properties {
  font-size: 16px;
  font-size: 14px;
  gap: 20px;
  flex-wrap: nowrap;
  padding: 10px;

  li {
    max-width: 33%;
  }
  p:nth-last-child(1) {
    font-weight: 600;
  }
}

.footer__invested-in {
  font-size: 12px;
  opacity: 0.5;
  line-height: 1;
  padding: 0 10px;
}

.risk-level {
  &.low {
    color: #2c9aea;
  }
  &.medium {
    color: #5f63f2;
  }
  &.high {
    color: #f5222d;
  }
}
</style>
